import React, { Component } from 'react'
import cookies from 'next-cookies'
import * as Sentry from '@sentry/node'

import { ErrorPage } from '../frontend'
import getSharedLayout from './shared-layout'

class Error extends Component {
  static async getInitialProps(ctx) {
    const { res, err } = ctx
    const statusCode = res ? res.statusCode : err ? err.statusCode : null

    const allCookies = cookies(ctx)

    let props = { statusCode, allCookies }

    if (err) {
      const stackTrace = err.stack ? err.stack.toString() : null
      props['error'] = {
        message: err.message,
        stack: stackTrace,
      }
      if (process.env.NODE_ENV === 'production') {
        Sentry.captureException(err)
        await Sentry.flush(2000)
      } else {
        console.error(err)
      }
    }

    return props
  }

  render() {
    return <ErrorPage {...this.props} />
  }
}

Error.getLayout = getSharedLayout

export default Error
