import React from 'react'
import Head from 'next/head'
import PropTypes from 'prop-types'

import { useSnippet, useTranslation } from '../../utils/providers'
import { ContentElements } from '../../patterns'
import getSharedLayout from '../../pages/shared-layout'

export default function ErrorPage({ statusCode }) {
  const { t } = useTranslation()
  const snippetElements = useSnippet('error_page_content')

  return (
    <>
      <Head>
        <title>
          {statusCode === 404
            ? t('ERROR_PAGE_NOT_FOUND')
            : t('ERROR_PAGE_TITLE')}
        </title>
      </Head>
      <div className="error-page container p-5">
        {statusCode === 404 ? (
          <ContentElements elements={snippetElements} />
        ) : (
          <h1>{t('INTERNAL_SERVER_ERROR')}</h1>
        )}
      </div>
    </>
  )
}

ErrorPage.propTypes = {
  statusCode: PropTypes.number,
}

ErrorPage.getLayout = getSharedLayout
